// Import action types
import { SET_FEED, CLEAR_FEED } from './types';

// Action creator to set the user
export const setGlobalFeed = (payload) => ({
  type: SET_FEED,
  payload: payload,
});

// Action creator to clear the user
export const clearGlobalFeed = () => ({
  type: CLEAR_FEED,
});