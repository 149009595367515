import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';
import { submitCommentWithRating, submitRating } from '../ScratchieExpress';

function CommentDialog(props) {
    const [comment, setComment] = useState('');
    const [error, setError] = useState('');

    const handleCommentChange = (event) => {
        const value = event.target.value;
        if (value.length > 1500) {
            setError('Comment cannot exceed 1500 characters');
        } else {
            setError('');
        }
        setComment(value);
    };

    const handleSubmit = async () => {
        try{
            props.setIsCurrentPageLoading(true)
            if (props.auth && props.isAuth && props.isAuthVerified && comment.length <= 1500) {
                if(!comment || comment === '' ){
                    await submitRating(props.currentTicket.UUID, props.auth.currentUser.uid, props.rating)
                }else{
                    await submitCommentWithRating(props.currentTicket.UUID, props.auth.currentUser.uid, props.rating, comment)
                }
                setComment('');
                props.handleAlertMessage("Rating successfully submitted", 'success');
                props.setIsCommentDialogOpen(false)
                props.initializePage()
            }else{
                props.handleAlertMessage("Ensure you are logged in and that your comment is valid.", 'error');
            }
        }catch(err){
            console.log(err)
            if(err.response && err.response.data && err.response.data.error){
                props.handleAlertMessage(err.response.data.error, 'error');
            }else{
                props.handleAlertMessage("Error submitting rating. Please try again.", 'error');
            }
        }finally{
            props.setIsCurrentPageLoading(false)
        }

    };

    const onClose = () =>{
        props.setIsCommentDialogOpen(false)
    }

    return (
        <Dialog open={props.isCommentDialogOpen} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Submit Your Feedback</DialogTitle>
            <DialogContent>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent:'center', marginBottom:10 }}>
                    <h6 style={{ margin: '0 3px 0 0', lineHeight: 1, fontWeight:'bold' }}>Your Rating: </h6>
                    <Rating value={props.rating} onChange={props.handleRatingChange} size="large"/>
                </div>

                <TextField
                    autoFocus
                    margin="dense"
                    label="Feel free to attach an optional comment to your rating!"
                    type="text"
                    fullWidth
                    multiline
                    sx={{
                        '& textarea': {
                            minHeight: '100px',
                        },
                        }}
                    value={comment}
                    onChange={handleCommentChange}
                    error={Boolean(error)}
                    helperText={error}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="contained" style={{backgroundColor:'rgba(0,0,0,.8)'}}>
                    Cancel
                </Button>
                <div style={{flex: '1 0 0'}} />
                <Button
                    onClick={handleSubmit}
                    variant="contained" 
                    color="primary"
                    disabled={comment.length > 1500}
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CommentDialog;
