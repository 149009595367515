// actions/historyActions.js

// Import action types
import { SET_HISTORY, CLEAR_HISTORY } from './types';

// Action creator to set the history
export const setGlobalHistory = (history) => ({
  type: SET_HISTORY,
  payload: history,
});

// Action creator to clear the history
export const clearGlobalHistory = () => ({
  type: CLEAR_HISTORY,
});
