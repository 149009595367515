// reducers/index.js

import { combineReducers } from 'redux';
import historyReducer from './historyReducer';
import feedReducer from './feedReducer';

const rootReducer = combineReducers({
  globalFeed: feedReducer,
  history: historyReducer
});

export default rootReducer;
