import React from 'react';
import { Box, Typography, Avatar, Rating, Paper } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const CommentBox = (props) => {
    const {comment } = props;
    const displayName = comment.first_name + " " + comment.last_name

    return (
        <Paper
            elevation={3}
            sx={{
                padding: 2,
                backgroundColor: '#f5f5f5', // Light gray background for contrast
                borderRadius: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
            }}
        >
            <Box display="flex" alignItems="center" gap={2}>
                {comment.photo_url && <Avatar
                    alt={displayName}
                    src={comment.photo_url}
                    sx={{ width: 40, height: 40 }}
                />}                
                {!comment.photo_url && <AccountCircleIcon
                    sx={{ width: 40, height: 40 }}
                />}
                <Typography variant="h6" component="div">
                    {displayName}
                </Typography>
            </Box>
            {comment.user_rating !== undefined && (
                <Rating
                    name="user-rating"
                    value={comment.user_rating}
                    precision={0.5}
                    readOnly
                />
            )}
            <Typography variant="body1">
                {comment.comment}
            </Typography>
        </Paper>
    );
};

export default CommentBox;
