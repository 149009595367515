import axios from "axios";
import axiosRetry from "axios-retry";

const customRetryDelay = (retryCount) => {
  if (retryCount === 1) {
    return 1000; // 1 second for the first retry
  } else if (retryCount === 2) {
    return 5000; // 5 seconds for the second retry
  } else {
    return 10000; // 10 seconds for subsequent retries
  }
};

axiosRetry(axios, {
  retries: 3, // Number of retries
  retryDelay: customRetryDelay, // Exponential backoff retry delay
  shouldResetTimeout: true, // Reset timeout on retries
});

const baseURL = process.env.REACT_APP_BASE_URL || "https://hedgehawgs.com";

export async function getMainTableData() {
  var config = {
    method: "get",
    url: baseURL + "/api/get/default",
    headers: {
      "Content-Type": "application/json",
    },
  };

  let response = await axios(config);
  console.log(response);

  return response.data;
}

export async function getTicketById(uuid, state) {
  var data = JSON.stringify({
    uuid: uuid,
    state: state,
  });

  var config = {
    method: "post",
    url: baseURL + "/api/get/ticket/state/id",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  let response = await axios(config);
  console.log(response);

  return response.data;
}

export async function getTicketsByState(state) {
  var data = JSON.stringify({
    state: state,
  });

  var config = {
    method: "post",
    url: baseURL + "/api/get/ticket/state/all",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  let response = await axios(config);

  return response.data;
}

export async function getMasterSearch(
  query,
  state,
  price,
  roi,
  netExpectedSort
) {
  var data = JSON.stringify({
    query: query,
    state: state,
    price: price,
    roi: roi,
    netExpectedSort: netExpectedSort,
  });

  var config = {
    method: "post",
    url: baseURL + "/api/get/master/search",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  let response = await axios(config);

  return response.data;
}

export async function createUserProfile(uuid, user_data) {
  var data = JSON.stringify({
    uuid: uuid,
    user_data: user_data,
  });

  var config = {
    method: "post",
    url: baseURL + "/api/personal/profile/create",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  const response = await axios(config);
  let result = response.data;
  return result;
}

export async function updateUserProfile(uuid, user_data) {
  var data = JSON.stringify({
    uuid: uuid,
    user_data: user_data,
  });

  var config = {
    method: "post",
    url: baseURL + "/api/personal/post/user/profile",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  const response = await axios(config);
  let result = response.data;
  return result;
}

export async function getUserProfile(uuid) {
  var data = JSON.stringify({
    uuid: uuid,
  });

  var config = {
    method: "post",
    url: baseURL + "/api/personal/get/user/profile",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  const response = await axios(config);
  let result = response.data;
  return result;
}

export async function submitRating(ticket_uuid, user_uuid, rating) {
  var data = JSON.stringify({
    ticket_uuid: ticket_uuid,
    user_uuid: user_uuid,
    rating: rating,
  });

  var config = {
    method: "post",
    url: baseURL + "/api/reaction/rating/insert",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  const response = await axios(config);
  let result = response.data;
  return result;
}

export async function submitComment(ticket_uuid, user_uuid, comment) {
  var data = JSON.stringify({
    ticket_uuid: ticket_uuid,
    user_uuid: user_uuid,
    comment: comment,
  });

  var config = {
    method: "post",
    url: baseURL + "/api/reaction/comment/insert",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  const response = await axios(config);
  let result = response.data;
  return result;
}

export async function submitCommentWithRating(
  ticket_uuid,
  user_uuid,
  rating,
  comment
) {
  var data = JSON.stringify({
    ticket_uuid: ticket_uuid,
    user_uuid: user_uuid,
    rating: rating,
    comment: comment,
  });

  var config = {
    method: "post",
    url: baseURL + "/api/reaction/comment/rating/insert",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  const response = await axios(config);
  let result = response.data;
  return result;
}

export async function getTicketComments(ticket_uuid) {
  var data = JSON.stringify({
    ticket_uuid: ticket_uuid,
  });

  var config = {
    method: "post",
    url: baseURL + "/api/reaction/ticket/comments",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  const response = await axios(config);
  let result = response.data;
  return result;
}
