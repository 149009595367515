import {useEffect, useState} from 'react';
import {Routes, Route} from 'react-router-dom';

import './App.css';
import LandingPage from './components/LandingPage';
import ScratchieWrapper from './components/ScratchieWrapper';
import { SnackbarProvider } from 'notistack';
import TicketFullPage from './components/TicketFullPage';

import { Provider } from 'react-redux';
import store from './store';
import SignIn from './components/SignIn';

import { getAuth, onAuthStateChanged } from "firebase/auth";
import AuthContext from './AuthContext';
import Profile from './components/Profile';



function App() {
  const auth = getAuth();
  const [isAuth, setIsAuth] = useState(false);
  const [isAuthVerified, setIsAuthVerified] = useState(false);
  const [isLoadingAuth, setIsLoadingAuth] = useState(true)
  const [photoUrl, setPhotoUrl] = useState(null)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuth(true);
        setIsAuthVerified(user.emailVerified);
        setIsLoadingAuth(false)
        setPhotoUrl(user.photoURL)
      } else {
        setIsAuth(false);
        setIsAuthVerified(false);
        setIsLoadingAuth(false)
      }
    });
  
    return () => unsubscribe();
  }, [auth]);

  return (
    <Provider store={store}>
      <AuthContext.Provider value={{auth, isAuth, isAuthVerified, isLoadingAuth}}>
        <SnackbarProvider maxSnack={3}>
          <div className="App">
            <Routes>
              <Route path="/" element={<ScratchieWrapper photoUrl={photoUrl} isScroll={true} element={<LandingPage/>}></ScratchieWrapper>} />
              <Route path="/ticket/:state/:ticketId" element={<ScratchieWrapper photoUrl={photoUrl} element={<TicketFullPage/>}></ScratchieWrapper>} />
              <Route path="/login" element={<ScratchieWrapper photoUrl={photoUrl} isScroll={false} element={<SignIn/>}></ScratchieWrapper>} />
              <Route path="/profile" element={<ScratchieWrapper photoUrl={photoUrl} isScroll={false} element={<Profile/>}></ScratchieWrapper>} />
            </Routes>
          </div>
        </SnackbarProvider>
      </AuthContext.Provider>
    </Provider>
  );
}

export default App;
