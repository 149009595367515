import React from 'react';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';

function TicketCard(props) {
  return (
    <Card sx={{ maxWidth: 345, width:345, height:315, transition: 'transform 0.2s ease-in-out' }}
    onMouseEnter={(e) => {
        e.currentTarget.style.transform = 'scale(1.05)';
        e.currentTarget.style.cursor = 'grab'; 
        }}
        onMouseLeave={(e) => {
        e.currentTarget.style.transform = 'scale(1)';
        e.currentTarget.style.cursor = 'pointer'; 
        }}
    onClick = {()=>props.handleTicketClick(props.currentTicket.UUID, props.currentTicket.STATE)}
    >
      <CardMedia
        component="img"
        height="150"
        image={props.currentTicket.IMAGE_LINK}
        alt={props.currentTicket.TICKET_NAME}
      />
      <CardContent>
        <Typography variant="h7" component="div" style={{fontWeight:'bold'}}>
          {props.currentTicket.TICKET_NAME}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {props.currentTicket.STATE && props.currentTicket.STATE.toUpperCase()}
        </Typography>
        <Typography variant="body2" color="text.primary">
          Price: ${props.currentTicket.PRICE}
        </Typography>
        <Typography variant="body2" color="text.primary">
          Net Expected Value: ${props.currentTicket.CURRENT_NET_EXPECTED_VALUE && props.currentTicket.CURRENT_NET_EXPECTED_VALUE.toFixed(2)}
        </Typography>
        <Typography variant="body2" color="text.primary">
          ROI: {props.currentTicket.CURRENT_ROI && props.currentTicket.CURRENT_ROI.toFixed(2)}%
        </Typography>
      </CardContent>
    </Card>
  );
}

export default TicketCard;
