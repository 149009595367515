import {React, useState, useReducer, useEffect, useContext} from 'react';
import {Container, Row, Col, Input} from 'reactstrap';
import { useMediaPredicate } from "react-media-hook";

import {provider} from '../firebase'

import GoogleButton from 'react-google-button'

import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';

import { createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail, sendEmailVerification, signInWithPopup, getAdditionalUserInfo, updateProfile, getAuth, onAuthStateChanged} from "firebase/auth";
import Button from '@mui/material/Button';


import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import {ALLSTATES} from '../data/AllStateAbbreviations'
import { SnackbarProvider, useSnackbar } from 'notistack';
import { Card, Paper, CircularProgress } from '@mui/material';
// import AuthContext from '../AuthContext';
import { createUserProfile} from '../ScratchieExpress';
import { analytics } from '../firebase'
import { logEvent } from "firebase/analytics";

import { useNavigate, useLocation } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import AuthContext from '../AuthContext';

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const validatePhoneNumber = (number) => {
    return String(number).match(
        /^(?:\+?1[-.\s]?)?\(?[2-9]\d{2}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/
    );
};

const validateStreetAddress = (address) => {
    return String(address).match(
        /^\d+\s+\S+\s.*$/
    );
};

const validateZipCode = (zip) => {
    return String(zip).match(
        /^\d{5}(?:[-\s]\d{4})?$/
    );
};

const validatePassword = (password) => {
    return String(password).match(
        /^(?=.*[A-Z])(?=.*\d).{8,}$/
    );
};

const stringsAreSame = (str1, str2) => {
    return str1 === str2;
};

function SignIn() {
    const { auth, isAuth, isAuthVerified, isLoadingAuth} = useContext(AuthContext);
    const [email, setEmail] = useState(null)
    const [password, setPassword] = useState(null)
    const [isEmailValid, setIsEmailValid] = useState(true)
    const [isPasswordValid, setIsPasswordValid] = useState(true)

    const [forgotEmail, setForgotEmail] = useState(null)
    const [isForgotEmailValid, setIsForgotEmailValid] = useState(true)

    const [isCreateOpen, setIsCreateOpen] = useState(false)
    const [togglePage, setTogglePage] = useState(false)


    const [userData, setUserData] = useState({first_name:null, middle_initial:null, last_name:null, phone_number:null, email:null, address:null, state:null, city:null, zip:null, password:null, confirm_password:null})
    const [isUserDataValid, setIsUserDataValid] = useState({first_name:true, middle_initial:true, last_name:true, phone_number:true, email:true, address:true, state:true, city:true, zip:true, confirm_password:true})
    const [isCurrentPageLoading, setIsCurrentPageLoading] = useState(false)

    const { enqueueSnackbar } = useSnackbar();

    const handleAlertMessage = (message, variant) => {
        enqueueSnackbar(message, {variant});
      };

    const IPhoneScreenMax = useMediaPredicate("(max-width: 599px)")
    const MobileScreenMin = useMediaPredicate("(min-width: 600px)")
    const MobileScreenMax = useMediaPredicate("(max-width: 991px)");
    const MediumScreenMin =useMediaPredicate("(min-width: 992px)");


    const [isOpen, setIsOpen] = useState(false)
    const [isResetOpen, setIsResetOpen] = useState(false)

    const [hasLoggedVisit, setHasLoggedVisit] = useState(false);

    const navigate = useNavigate();
  
    // useEffect(() => {
    //     if (!hasLoggedVisit) {
    //         logEvent(analytics, 'page_view', { page_title: 'Sign In' });
    //         setHasLoggedVisit(true);
    //       }
    // }, [hasLoggedVisit]);


    const handleClose = () => {
        setIsOpen(false)
        setIsCreateOpen(false)
        // window.location.href ="/"
        navigate('/profile');
      };
    const changePage = () =>{
        setTogglePage(!togglePage)
        reset()
    }

    const updateEmailandPassword = (event) =>{
        let target = event.target;
        let value = target.value;
        let id = target.id;

        switch(id){
            case "filled-username-input":
                setEmail(value)
                if(value !== null && value !== "" && validateEmail(value)){
                    setIsEmailValid(true)
                }else{
                    setIsEmailValid(false)
                }
                break
            case "filled-password-input":
                setPassword(value)
                if(value !== null && value !== "" && validatePassword(value)){
                    setIsPasswordValid(true)
                }else{
                    setIsPasswordValid(false)
                }
                break
            case "forgot-email":
                setForgotEmail(value)
                if(value !== null && value !== "" && validateEmail(value)){
                    setIsForgotEmailValid(true)
                }else{
                    setIsForgotEmailValid(false)
                }
                break
            default:
                break
        }

    }



    // useEffect(() =>{
    //     let isSignUp = sessionStorage.getItem("isSignUp")
    //     if(isSignUp){
    //         if(isSignUp === "true"){
    //             setTogglePage(true)
    //             sessionStorage.removeItem("isSignUp")
    //         }else{
    //             sessionStorage.removeItem("isSignUp")
    //         }
    //     }
    // }, [])

    function hashPassword(password) {
        const hash = crypto.createHash('sha256');
        hash.update(password);
        return hash.digest('hex');
    }
    

    const createNewUser = async () => {
        try {
            setIsCurrentPageLoading(true)
            if (!validateAllFields()) {
                handleAlertMessage("Please review form. Invalid entries are marked red.", 'error');
            } else if (userData["confirm_password"] !== userData["password"]) {
                handleAlertMessage("Passwords do not match.", 'error');
            } else {
                const userDataBackup = _.cloneDeep(userData)
                delete userDataBackup.password
                delete userDataBackup.confirm_password

                const jsonString = JSON.stringify(userDataBackup);
                localStorage.setItem('user_backup', jsonString);

                const userCredential = await createUserWithEmailAndPassword(auth, userData["email"], userData["password"]);
                const user = userCredential.user;

                await createUserProfile(user.uid, userDataBackup);
                await sendEmailVerification(user);
                setIsCreateOpen(true);
                handleAlertMessage("Successfully created your profile!", 'success');
            }
            setIsCurrentPageLoading(false)
        } catch (error) {
            handleAlertMessage(error.message, 'error'); 
            setIsCurrentPageLoading(false)
        }
    };

    const signInWithEmail = async () => {
        try {
            setIsCurrentPageLoading(true)
            if (!isPasswordValid || !isEmailValid || !email || !validateEmail(email) || !password || !validatePassword(password)) {
                handleAlertMessage("Invalid fields detected. Please ensure the email and password fields are filled in.", 'error');
            } else {
                let hashedPassword = hashPassword(password)
                await signInWithEmailAndPassword(auth, email, hashedPassword);
                setIsOpen(true);
            }
            setIsCurrentPageLoading(false)
        } catch (error) {
            console.log(error.code)
            if(error.code && error.code == "auth/user-not-found"){
                handleAlertMessage('User not found', 'error');
            }else if(error.code && error.code == "auth/wrong-password"){
                handleAlertMessage('Incorrect password', 'error');
            }else if(error.code && error.code == "auth/too-many-requests"){
                handleAlertMessage('Account temporarily disabled. Too many incorrect attempts.', 'error');
            }else{
                handleAlertMessage('Error signing in. Please try again.', 'error');
            }
            setIsCurrentPageLoading(false)
        }
    };

    const signInWithGoogleClick = async () => {
        setIsCurrentPageLoading(true)
        try {
            const result = await signInWithPopup(auth, provider);
            let user = result.user
            const isNewUser = getAdditionalUserInfo(result).isNewUser;
    
            if (isNewUser) {
                let first_name = null;
                let last_name = null;
                let email = null;
                let phone_number = null;
                let photo_url = null;
    
                if (user.displayName) {
                    const nameParts = user.displayName.split(' ');
                    first_name = nameParts[0];
                    last_name = nameParts.slice(1).join(' ');
                }
                if (user.email) {
                    email = user.email;
                }
                if (user.phoneNumber) {
                    phone_number = user.phoneNumber;
                }
                if (user.photoURL) {
                    photo_url = user.photoURL;
                }
    
                await createUserProfile(user.uid, {
                    first_name: first_name,
                    middle_initial: null,
                    last_name: last_name,
                    email: email,
                    phone_number: phone_number,
                    address: null,
                    city: null,
                    state: null,
                    zip: null,
                    photo_url: photo_url
                });
                await sendEmailVerification(user);
            }
    
            setIsOpen(true);
            setIsCurrentPageLoading(false)
        } catch (error) {
            handleAlertMessage("(" + error.code + "): " + error.message, 'error');
            setIsCurrentPageLoading(false)
        }
    };
    

    const sendResetLink = async () => {
        try {
            if (!isForgotEmailValid) {
                handleAlertMessage("Invalid Email.", 'error');
                return;
            }
    
            await sendPasswordResetEmail(auth, forgotEmail);
            setIsResetOpen(false);
            handleAlertMessage("Please check your email", 'success');
            setForgotEmail(null);
        } catch (error) {
            console.log(error);
            handleAlertMessage("There was an error. Please try again.", 'error');
        }
    };

   

    const reset = () =>{
        setEmail(null)
        setPassword(null)
        setForgotEmail(null)
        setIsUserDataValid(prevUserData => ({
            ...prevUserData,
            first_name: true,
            last_name:true,
            middle_initial:true,
            email:true,
            password:true,
            confirm_password:true,
            phone_number:true,
            address:true,
            city:true,
            state:true,
            zip:true
        }));

        setUserData(prevUserData => ({
            ...prevUserData,
            first_name: null,
            last_name:null,
            middle_initial:null,
            email:null,
            password:null,
            confirm_password:null,
            phone_number:null,
            address:null,
            city:null,
            state:null,
            zip:null
        }));

    }

      const updateUserData = (event, identification) => {
        let target
        let value
        let id
        if(!identification){
            target = event.target;
            value = target.value;
            id = target.id;
        }else{
            id = identification
            value = event
        }
        validateFields(id, value)

        setUserData(prevUserData => ({
            ...prevUserData,
            [id]: value
        }));
    }

    const validateAllFields = () => {
        const fields = [
            "first_name", "last_name",
            "email", "phone_number", "address", "city", "state", "zip", "password", "confirm_password"
        ];
    
        let allFieldsValid = true; // Start by assuming all fields are valid
    
        fields.forEach(field => {
            const isValid = validateFields(field, userData[field]);
            if (!isValid) {
                allFieldsValid = false;
            }
        });
    
        return allFieldsValid;
    }

    const validateFields = (target, value) =>{
        switch(target){
            case "first_name":
                if (value === null || value === "" || (value.length <= 2 || value.length >= 100)) {
                    setIsUserDataValid(prevUserData => ({
                        ...prevUserData,
                        first_name: false
                    }));
                    return false
                }else{
                    setIsUserDataValid(prevUserData => ({
                        ...prevUserData,
                        first_name: true
                    }));
                    return true
                }
            case "last_name":
                if (value === null || value === "" || (value.length <= 2 || value.length >= 100)) {
                    setIsUserDataValid(prevUserData => ({
                        ...prevUserData,
                        last_name: false
                    }));
                    return false
                }else{
                    setIsUserDataValid(prevUserData => ({
                        ...prevUserData,
                        last_name: true
                    }));
                    return true
                }
            case "middle_initial":
                if (value === null || value === "" || value.length !== 1 ) {
                    setIsUserDataValid(prevUserData => ({
                        ...prevUserData,
                        middle_initial: false
                    }));
                    return false
                }else{
                    setIsUserDataValid(prevUserData => ({
                        ...prevUserData,
                        middle_initial: true
                    }));
                    return true
                }
            case "email":
                if(value === null || value === "" || !validateEmail(value)){
                    setIsUserDataValid(prevUserData => ({
                        ...prevUserData,
                        email: false
                    }));
                    return false
                }else{
                    setIsUserDataValid(prevUserData => ({
                        ...prevUserData,
                        email: true
                    }));
                    return true
                }
            case "phone_number":
                if(value === null || value === "" || !validatePhoneNumber(value)){
                    setIsUserDataValid(prevUserData => ({
                        ...prevUserData,
                        phone_number: false
                    }));
                    return false
                }else{
                    setIsUserDataValid(prevUserData => ({
                        ...prevUserData,
                        phone_number: true
                    }));
                    return true
                }
            case "address":
                if(value && !validateStreetAddress(value)){
                    setIsUserDataValid(prevUserData => ({
                        ...prevUserData,
                        address: false
                    }));
                    return false
                }else{
                    setIsUserDataValid(prevUserData => ({
                        ...prevUserData,
                        address: true
                    }));
                    return true
                }
            case "city":
                return true
            case "state":
                return true
            case "zip":
                if(value && !validateZipCode(value)){
                    setIsUserDataValid(prevUserData => ({
                        ...prevUserData,
                        zip: false
                    }));
                    return false
                }else{
                    setIsUserDataValid(prevUserData => ({
                        ...prevUserData,
                        zip: true
                    }));
                    return true
                }
            case "password":
                if(value === null || value === "" || !validatePassword(value)){
                    setIsUserDataValid(prevUserData => ({
                        ...prevUserData,
                        password: false
                    }));
                    return false
                }else{
                    setIsUserDataValid(prevUserData => ({
                        ...prevUserData,
                        password: true
                    }));
                    return true
                }
            case "confirm_password":
                if(value === null || value === "" || !stringsAreSame(value,userData["password"])){
                    setIsUserDataValid(prevUserData => ({
                        ...prevUserData,
                        confirm_password: false
                    }));
                    return false
                }else{
                    setIsUserDataValid(prevUserData => ({
                        ...prevUserData,
                        confirm_password: true
                    }));
                    return true
                }
            
            default: console.log("No target specified")
        }
    }

    return(
    <div style={{backgroundColor:'#f5f5f5'}}>
    {!togglePage && 
    (<div >
        <div style={{height:'100vh'}}>
        <div style={{height:"100%"}}>
        
        {!togglePage && MediumScreenMin && <div style={{display:'flex', width:"100%", textAlign:'left', height:"100%"}}>

            <div style={{width:'100%', display:'flex', justifyContent:'center'}} className='px-5 py-5'>
                <div style={{width:'50%'}}>
                    <Paper className='px-5 py-5'>
                        <h2 >Login</h2>
                        <div style={{paddingTop:25, paddingBottom:25}}>
                            <GoogleButton style={{width:"100%"}} type = "dark" onClick={signInWithGoogleClick}/>
                        </div>
                        <h4 style={{textAlign:'center', paddingBottom:25}} >or use your account</h4>
                        <div>
                            <TextField style={{width:"100%"}} error={!isEmailValid} id="filled-username-input" label="Email" type="email" variant="filled" name="email" onChange={updateEmailandPassword} value={email} />
                        </div>
                        <div style={{paddingTop:15}}>
                            <TextField style={{width:"100%"}} error={!isPasswordValid} id="filled-password-input" label="Password" type="password" autoComplete="current-password" variant="filled" name="password" onChange={updateEmailandPassword} value={password}  />
                        </div>
                        <p style={{ paddingTop:15, paddingBottom:10}}><span><Button style={{height:25}} onClick= {setIsResetOpen}>Forgot Password?</Button></span></p>       
                        <div>
                        <div style={{display:'flex', justifyContent:'center'}}>
                        <Button variant="contained" style={{ textDecoration:'none', width:200}} onClick={signInWithEmail} >Log In</Button>
                        </div>
                        </div>
                        <div style={{display:"flex", justifyContent:"center"}}>
                        <p style={{paddingBottom:35, textAlign:'center', paddingTop:15}}> Need an account? <Button style={{height:25, float:"right"}} onClick={changePage}>Sign Up</Button></p>
                        </div>
                    </Paper>
                </div>
            </div>
            {/* {MediumScreenMin && !togglePage && (
            <div className="lawBGSign" style={{display:'flex', width:'50%', height:'100%'}} >
                <div style={{width:'100%'}} className='px-5 py-3'>
                </div>
            </div>
        )} */}
        </div>}


        {!togglePage && MobileScreenMin && MobileScreenMax && <div style={{display:'flex', justifyContent:"center", textAlign:'left', height:""}}>

            <div style={{ width:'85%', display:'flex', justifyContent:'center'}} className='px-5 py-5'>
                <div style={{width:'100%'}}>
                    <Paper className='px-5 py-5'>
                        <h2>Login</h2>
                        <div style={{paddingTop:25, paddingBottom:25}}>
                            <GoogleButton style={{width:"100%"}} type = "dark" onClick={signInWithGoogleClick}/>
                        </div>
                        <h4 style={{textAlign:'center', paddingBottom:25}} >or use your account</h4>
                        <div>
                            <TextField style={{width:"100%"}} error={!isEmailValid} id="filled-username-input" label="Email" type="email" variant="filled" name="email" onChange={updateEmailandPassword} value={email} />
                        </div>
                        <div style={{paddingTop:15}}>
                            <TextField style={{width:"100%"}} error={!isPasswordValid} id="filled-password-input" label="Password" type="password" autoComplete="current-password" variant="filled" name="password" onChange={updateEmailandPassword} value={password} />
                        </div>
                        <p style={{ paddingTop:15, paddingBottom:10}}><span><Button style={{height:25}} onClick= {setIsResetOpen}>Forgot Password?</Button></span></p>       
                        <div>
                        <div style={{display:'flex', justifyContent:'center'}}>
                        <Button variant="contained" style={{ textDecoration:'none', width:200}} onClick={signInWithEmail} >Log In</Button>
                        </div>
                        </div>
                        <div style={{display:"flex", justifyContent:"center"}}>
                        <p style={{paddingBottom:35, textAlign:'center', paddingTop:15}}> Need an account? <Button style={{height:25, float:"right"}} onClick={changePage}>Sign Up</Button></p>

                        </div>
                    </Paper>
                </div>
            </div>
        </div>}
        {!togglePage && IPhoneScreenMax && <div style={{display:'flex', justifyContent:"center", textAlign:'left', height:"100%", backgroundColor:'white'}}>
            <div style={{ width:'95%', display:'flex', justifyContent:'center', marginTop:"5%"}} className='px-5 py-5'>
                <div style={{width:'100%'}}>
                        <h2>Login</h2>
                        <div style={{paddingTop:25, paddingBottom:25}}>
                            <GoogleButton style={{width:"100%"}} type = "dark" onClick={signInWithGoogleClick}/>
                        </div>
                        <h4 style={{textAlign:'center', paddingBottom:25}} >or use your account</h4>
                        <div>
                            <TextField style={{width:"100%"}} error={!isEmailValid} id="filled-username-input" label="Email" type="email" variant="filled" name="email" onChange={updateEmailandPassword} value={email}/>
                        </div>
                        <div style={{paddingTop:15}}>
                            <TextField style={{width:"100%"}} error={!isPasswordValid} id="filled-password-input" label="Password" type="password" autoComplete="current-password" variant="filled" name="password" onChange={updateEmailandPassword} value={password} />
                        </div>
                        <p style={{ paddingTop:15, paddingBottom:10}}><span><Button style={{height:25}} onClick= {setIsResetOpen}>Forgot Password?</Button></span></p>       
                        <div>
                        <div style={{display:'flex', justifyContent:'center'}}>
                        <Button variant="contained" style={{ textDecoration:'none', width:200}} onClick={signInWithEmail} >Log In</Button>
                        </div>
                        </div>
                        <div style={{display:"flex", justifyContent:"center"}}>
                        <p style={{paddingBottom:35, textAlign:'center', paddingTop:15}}> Need an account? <Button style={{height:25, float:"right"}} onClick={changePage}>Sign Up</Button></p>

                        </div>
                </div>
            </div>
            </div>}
 
        </div>
      
    </div>
    </div>)}


    {togglePage && <div>
        {togglePage && (
            <div  className='px-5 py-5'>
                <div>
                    <Paper className='px-5 py-5'>
                        <h2 style={{textAlign:"left"}} >Sign Up</h2>
                        <div style={{paddingTop:25, paddingBottom:25}}>
                            <GoogleButton style={{width:"100%"}} type = "dark" onClick={signInWithGoogleClick}/>
                        </div>
                        <h4 style={{textAlign:'center', paddingBottom:25}} >or create a email/password account</h4>
                        <Container>
                            <Row >
                                    <Col xs="12" sm="5" md="5" lg="5" xl="5" style={{marginTop:20}}>
                                        <TextField id="first_name" name="first_name" label="First Name" variant="filled" fullWidth value={userData["first_name"]} error={!isUserDataValid["first_name"]} onChange={updateUserData} placeholder = "Ex. Johnny " InputLabelProps={{ shrink: true }}/>
                                    </Col>
                                    <Col xs="12" sm="2" md="2" lg="2" xl="2" style={{marginTop:20}}>
                                        <TextField id="middle_initial" name="middle_initial" label="Middle Initial" variant="filled" fullWidth value={userData["middle_initial"]}  error={!isUserDataValid["middle_initial"]}  onChange={updateUserData} placeholder = "Ex. D" InputLabelProps={{ shrink: true }}/>
                                    </Col>
                                    <Col xs="12" sm="5" md="5" lg="5" xl="5" style={{marginTop:20}}>
                                        <TextField id="last_name" name="last_name" label="Last Name" variant="filled" fullWidth value={userData["last_name"]} error={!isUserDataValid["last_name"]} onChange={updateUserData} placeholder = "Ex. Appleseed" InputLabelProps={{ shrink: true }}/>
                                    </Col>
                            </Row>
                            <Row >
                                <Col xs='12' sm='12' md='12' lg ='12' xl='12' style={{marginTop:20}}>
                                    <TextField id="email" name="email" label="Email" variant="filled" type="email" fullWidth value={userData["email"]} error={!isUserDataValid["email"]} onChange={updateUserData} placeholder = "Ex. Jappleseed@gmail.com" InputLabelProps={{ inputMode:"email" ,shrink: true }}/>
                                </Col>
                            </Row>
                            <Row >
                                <Col xs='12' sm='12' md='12' lg ='12' xl='12' style={{marginTop:20}}>
                                    <TextField id="phone_number" name="phone_number" label="Phone Number" variant="filled" type="tel" fullWidth value={userData["phone_number"]} error={!isUserDataValid["phone_number"]} onChange={updateUserData} InputLabelProps={{ inputMode:"tel", shrink: true }} placeholder="Ex. 908-765-0987"/>                                
                                </Col>
                            </Row>
                            <Row >
                                <Col xs='12' sm='12' md='6' lg ='6' xl='6' style={{marginTop:20}}>
                                    <TextField id="address" name="address" label="Address" variant="filled" fullWidth error={!isUserDataValid["address"]} value= {userData["address"]} onChange={updateUserData} InputLabelProps={{ shrink: true }} placeholder="Ex. 6 Blueberry Lane"/>
                                </Col>
                                <Col xs='12' sm='12' md='6' lg ='6' xl='6' style={{marginTop:20}}>
                                    <TextField id="city" name="city" label="City" variant="filled" fullWidth error={!isUserDataValid["city"]} value= {userData["city"]} onChange={updateUserData} InputLabelProps={{ shrink: true }} placeholder="Ex. Austin"/>
                                </Col>
                            </Row>
                            <Row >
                                <Col xs='12' sm='12' md='6' lg ='6' xl='6' style={{marginTop:20}}>
                                    <Autocomplete id="state" name="state" label="State" variant="filled" fullWidth  error={!isUserDataValid["state"]} value= {userData["state"]} onChange={(event, selectedOption) => {updateUserData(selectedOption.value, "state"); }} disablePortal={true} options={ALLSTATES} renderInput={(params) => <TextField {...params} placeholder="Ex. TX" InputLabelProps={{ shrink: true }}  id="state" name="state" label="State"  variant="filled" fullWidth />}/>
                                </Col>
                                <Col xs='12' sm='12' md='6' lg ='6' xl='6' style={{marginTop:20}}>
                                    <TextField  id="zip" name="zip" label="Zip" variant="filled" fullWidth error={!isUserDataValid["zip"]} value={userData["zip"]} onChange={updateUserData} InputLabelProps={{ shrink: true }} placeholder="Ex. 78701"/>
                                </Col>
                            </Row>
                            <Row >
                                <Col xs='12' sm='12' md='6' lg ='6' xl='6' style={{marginTop:20}}>
                                    <TextField error={!isUserDataValid["password"]} id="password" value={userData["password"]} helperText={!isUserDataValid["password"] && "Must contain greater than seven characters, one capital character and one number"} onChange={updateUserData} label="Password" type="password" autoComplete="current-password" variant="filled" name="password"  fullWidth/>
                                </Col>
                                <Col xs='12' sm='12' md='6' lg ='6' xl='6' style={{marginTop:20}}>
                                    <TextField  error={!isUserDataValid["confirm_password"]} value={userData["confirm_password"]} onChange={updateUserData} helperText={!isUserDataValid["confirm_password"] && "Passwords do not match"}  id="confirm_password" label="Confirm Password" type="password" variant="filled" name="confirm_password"  fullWidth/>
                                </Col>
                            </Row>
                            <Row>
                                <div style={{marginTop:20, display:'flex', justifyContent:'center'}}>
                                    <p> By signing up, you accept Scratchie Hub's <a href='\terms' target="_blank">Terms of Use</a> and <a href='\privacy' target="_blank">Privacy Policy</a></p>
                                </div>
                                <div>
                                <div style={{display:'flex', justifyContent:'center', marginTop:20}}>
                                    <Button variant="contained" style={{ textDecoration:'none', width:200}} onClick={createNewUser}>Sign Up</Button>
                                </div>
                                </div>
                                <div style={{display:"flex", justifyContent:"center"}}>
                                    <p style={{paddingBottom:35, textAlign:'center', paddingTop:15}}> Already have an account? <Button style={{height:25, float:"right"}} onClick= {changePage}>Log In</Button></p>
                                </div>
                            </Row>
                        </Container>
                    </Paper>
                </div>
            </div>
        )}
    </div>}

    {isCurrentPageLoading && (
            <div className="overlay">
                  <CircularProgress style={{width:100, height:100}}/>
            </div>
        )}

    <Dialog open={isCreateOpen} onClose={handleClose}>
        <DialogTitle>Login Successful!</DialogTitle>
        <DialogContent>
        <DialogContentText >
            Thank you for creating your Scratchie Hub account. Please check your inbox for a verification email.  
        </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        </DialogActions>
    </Dialog>

    <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>Login Successful!</DialogTitle>
        <DialogContent>
        <DialogContentText >
            You have just logged into your Scratchie Hub account.
        </DialogContentText>

        </DialogContent>
        <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        </DialogActions>
    </Dialog>

    <Dialog open={isResetOpen} onClose={() =>setIsResetOpen(false)}>
                <DialogTitle>Password Reset</DialogTitle>
                <DialogContent>
                <DialogContentText >
                   In order to reset your password please enter your email.
                </DialogContentText>
                <div style={{marginTop:25}}>
                    <TextField style={{width:"100%"}} error={!isForgotEmailValid} id="forgot-email" label="Email" type="email" variant="filled" name="forgot-email" onChange={updateEmailandPassword} value={forgotEmail} />
                </div>
                <div style={{display:"flex", justifyContent:"center", marginTop:25}}>
                <Button variant = "contained" style={{height:50, width:200}} onClick= {sendResetLink}>Send Email</Button>
                </div>
                </DialogContent>
                <DialogActions>
                <Button onClick={() =>setIsResetOpen(false)}>Close</Button>
        </DialogActions>
    </Dialog>

    </div>
    );
}

export default SignIn; 