// reducers/historyReducer.js

// Import action types
import { SET_HISTORY, CLEAR_HISTORY } from '../actions/types';

// Initial state for the history slice
const initialState = [];

// History reducer function
const historyReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HISTORY:
      return action.payload;
    case CLEAR_HISTORY:
      return [];
    default:
      return state;
  }
};

export default historyReducer;
