import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { Card, CircularProgress, IconButton, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSnackbar } from 'notistack';
import { useMediaPredicate } from 'react-media-hook';
import moment from 'moment'; // Import moment.js

// Custom components


// Redux
import { useSelector, useDispatch } from 'react-redux';

// API functions


// Utility
import _ from 'lodash';
import { getTicketById, getTicketComments } from '../ScratchieExpress';

//Icons
import { HiTrophy } from "react-icons/hi2";
import { GiCash } from "react-icons/gi";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { FaHourglassEnd } from "react-icons/fa";
import { TbSum } from "react-icons/tb";
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { FaCashRegister } from "react-icons/fa";
import { FaCalendarAlt } from "react-icons/fa";
import TicketImage from './TicketImage';
import PrizeTable from './PrizeTable';

import Rating from '@mui/material/Rating';
import CommentDialog from './CommentDialog';
import AuthContext from '../AuthContext';
import CommentBox from './CommentBox';

function TicketFullPage(props) {
    const {auth, isAuth, isAuthVerified, isLoadingAuth} = useContext(AuthContext);
    const [isCurrentPageLoading, setIsCurrentPageLoading] = useState(true);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [restoredScrollPosition, setRestoredScrollPosition] = useState(0);
    const [searchTimeout, setSearchTimeout] = useState(null);

    const {state, ticketId} = useParams();
    const [currentTicket, setCurrentTicket] = useState({PRIZE_TABLE:{}})
    const [comments, setComments] = useState([])

    const [isStateDoneLoading, setIsStateDoneLoading] = useState(false);
    const [isMoreLoading, setIsMoreLoading] = useState(false);

    const mainContentRef = useRef(null);
    const isMasterCommentsEmpty = useRef(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const MobileScreen = useMediaPredicate("(max-width: 991px)");
    const RealMobileScreen = useMediaPredicate("(max-width: 767px)");

    const [isCommentDialogOpen, setIsCommentDialogOpen] = useState(false)

    const [rating, setRating] = useState(5);

    const handleAlertMessage = (message, variant) => {
        enqueueSnackbar(message, { variant });
    };

    const initializePage = async () => {
        try {
            const [ticketData, ticketComments] = await Promise.all([
                getTicketById(ticketId, state),
                getTicketComments(ticketId)
            ]);

            ticketData.PRIZE_TABLE = JSON.parse(ticketData["PRIZE_TABLE"])
            setCurrentTicket(ticketData)
            setComments(ticketComments)
            if(ticketData.USER_RATING){
                setRating(ticketData.USER_RATING)
            }
        } catch (err) {
            console.log(err)
            handleAlertMessage("Error retrieving ticket. Please reload the page.", 'error');
        } finally {
            setIsCurrentPageLoading(false);
        }
    };

    useEffect(() => {
        if (isStateDoneLoading) {
            initializePage()
        }
    }, [isStateDoneLoading]);

    useEffect(() => {
        if (!isStateDoneLoading) {
            setIsCurrentPageLoading(true);
            if (ticketId !== undefined && state !== undefined) {
                setIsStateDoneLoading(true);
            }
        }
    }, [state, ticketId]);

    const handleBackButtonClick = () => {
        navigate("/");
    };

    useEffect(() => {
        restoreScroll();
    }, [restoredScrollPosition]);

    const handleMainContentScroll = () => {
        if (mainContentRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = mainContentRef.current;
            setScrollPosition(scrollTop);

            const scrollBuffer = 50;
            if (scrollTop + clientHeight >= scrollHeight - scrollBuffer) {
            }
        }
    };

    const restoreScroll = () => {
        if (mainContentRef.current) {
            mainContentRef.current.scrollTop = restoredScrollPosition;
        }
    };

    const scrollToTop = () => {
        if (mainContentRef.current) {
            mainContentRef.current.scrollTop = 0;
            setScrollPosition(0);
        }
    };

    const handleRatingChange = (event, newValue) =>{
        if(isAuth && isAuthVerified){
            setRating(newValue)
            setIsCommentDialogOpen(true)
        }else{
            handleAlertMessage("You must be logged into a verified Scratchie Hub account to use this feature", 'error')
        }

    }

    return (
        <div style={{ maxWidth: "100%", width: '100%'}}>
            {!isCurrentPageLoading && <div ref={mainContentRef} onScroll={handleMainContentScroll} style={{ height: '100%', overflowY: 'scroll' }}>
                {!isCurrentPageLoading && (
                    <Container style={{ marginBottom: 100 }}>
                        <Row style={{ paddingTop: 8 }}>
                            <Col>
                                <div style={{ display: 'flex', justifyContent: 'left', alignContent: 'center' }}>
                                    <IconButton onClick={handleBackButtonClick}>
                                        <ArrowBackIcon style={{ width: 35, height: 35, color: 'black' }} />
                                    </IconButton>
                                    <h4 className="TimesNewRoman" style={{ marginLeft: 'auto', fontWeight: 'bold', alignContent: 'center' }}> Ticket Details</h4>
                                </div>
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: 15 }}>
                            <Card className='px-3 py-3' style={{ borderRadius: 15 }}>
                                <Row style={{borderBottom:'solid 1px #C2C2C2'}}>
                                    <Col xs="12" sm="12" md="12" lg="4" xl="4" style={{paddingBottom:15}}>
                                        <div style={{ display: 'flex', marginBottom: 15, borderRadius: '8px', alignItems: 'center', justifyContent: 'space-between'}}>
                                            <div style={{ textAlign: 'left'}}>
                                                <h2 style={{ fontWeight: 'bold', marginBottom: '5px', color: '#333', fontSize: '30px' }}>
                                                    {currentTicket.TICKET_NAME}
                                                </h2>  
                                                <h5 style={{ margin: '0', color: '#666', fontSize: '18px' }}>
                                                    Game #{currentTicket.TICKET_NUMBER} - {currentTicket.STATE && currentTicket.STATE.toUpperCase()}
                                                </h5>
                                            </div>

                                        </div>
                                        <TicketImage
                                            src={currentTicket.IMAGE_LINK} 
                                            alt="Ticket" 
                                        />
                                    </Col>
                                    <Col xs="12" sm="12" md="12" lg="8" xl="8" style={{borderLeft: !MobileScreen ? 'solid 1px #C2C2C2' : 'none', paddingBottom:15}}>

                                        {!RealMobileScreen && <Row style={{borderBottom:'solid 1px #C2C2C2',borderTop: MobileScreen ? 'solid 1px #C2C2C2' : 'none',marginTop:MobileScreen ? 15 : 0, alignItems:'center'}}>
                                            <Col xs="12" sm="12" md="12" lg="12" xl="12" className="py-2">
                                                <div style={{ display:'flex', justifyContent:'left'}}>
                                                    <div>
                                                        <h5 style={{ margin: '0', color: '#999', fontSize: '16px', textAlign:'left' }}>
                                                            Last Updated: &nbsp;
                                                            <span style={{ margin: '0', color: '#333', fontSize: '16px' }}>
                                                            {moment(currentTicket.UPDATED_DT).format('MMMM D, YYYY [at] h:mm A')}
                                                            </span>
                                                        </h5>
                                        
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xs="12" sm="12" md="6" lg="6" xl="6" className="py-2">
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent:'left' }}>
                                                    {currentTicket.USER_RATING && <h6 style={{ margin: '0 3px 0 0', lineHeight: 1, fontWeight:'bold' }}>{currentTicket.USER_RATING}</h6>}
                                                    <Rating value={rating} onChange={handleRatingChange} size="large"/>
                                                    <h6 style={{lineHeight: 1, margin: '0 0 0 0' }}>({currentTicket.RATING_COUNT} ratings)</h6>
                                                </div>
                                            </Col>
                                            <Col xs="12" sm="12" md="6" lg="6" xl="6" className="py-2">
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent:'right' }}>
                                                    <GiCash style={{ width: 65, height: 65, marginRight: 10 }} />
                                                    <div style={{textAlign:'left'}}>
                                                    <h5 style={{ margin: '0', color: '#999', fontSize: '16px' }}>
                                                        Top Prize
                                                    </h5>
                                                    <h3 style={{ fontWeight: 'bold', marginBottom: 0, color: '#333', fontSize: '35px' }}>
                                                        ${currentTicket.TOP_PRIZE}
                                                    </h3>  
                                                    </div>
                                                </div>
                                            </Col>

                                            {/* <Col xs="6" sm="6" md="6" lg="6" xl="6">
                                                <div style={{ textAlign: 'right'}}>
                                                    <h5 style={{ margin: '0', color: '#999', fontSize: '16px' }}>
                                                        Last Updated
                                                    </h5>
                                                    <h4 style={{ margin: '0', color: '#333', fontSize: '20px' }}>
                                                        {moment(currentTicket.UPDATED_DT).format('MMMM D, YYYY [at] h:mm A')}
                                                    </h4>
                                                </div>
                                            </Col> */}
                                        </Row>}
                                        {RealMobileScreen && <Row style={{borderBottom:'solid 1px #C2C2C2',borderTop: MobileScreen ? 'solid 1px #C2C2C2' : 'none',marginTop:MobileScreen ? 15 : 0, alignItems:'center'}}>
                                                <div style={{ display:'flex', justifyContent:'left', marginTop:5, marginBottom:10}}>
                                                    <div>
                                                        <h5 style={{ margin: '0', color: '#999', fontSize: '16px', textAlign:'left' }}>
                                                            Last Updated: &nbsp;
                                                            <span style={{ margin: '0', color: '#333', fontSize: '16px' }}>
                                                            {moment(currentTicket.UPDATED_DT).format('MMMM D, YYYY')}
                                                            </span>
                                                        </h5>
                                        
                                                    </div>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent:'center', marginBottom:20 }}>
                                                    {currentTicket.USER_RATING && <h6 style={{ margin: '0 3px 0 0', lineHeight: 1, fontWeight:'bold' }}>{currentTicket.USER_RATING}</h6>}
                                                    <Rating value={rating} onChange={handleRatingChange} size="large"/>
                                                    <h6 style={{lineHeight: 1, margin: '0 0 0 0' }}>({currentTicket.RATING_COUNT} ratings)</h6>
                                                </div>
                                                <div style={{ display:'flex', justifyContent:'center'}}>
                                                    <div  style={{ display: 'flex', alignItems: 'center' }}>
                                                        <GiCash style={{ width: 65, height: 65, marginRight: 10 }} />
                                                        <div style={{textAlign:'left'}}>
                                                        <h5 style={{ margin: '0', color: '#999', fontSize: '16px' }}>
                                                            Top Prize
                                                        </h5>
                                                        <h3 style={{ fontWeight: 'bold', marginBottom: 0, color: '#333', fontSize: '35px' }}>
                                                            {currentTicket.TOP_PRIZE}
                                                        </h3>  
                                                        </div>
                                                    </div>
                                                </div>
                                        </Row>}
                                        <Row style={{borderBottom:'solid 1px #C2C2C2', paddingBottom:15}}>
                                        <h3 className="px-3 py-2" style={{ fontWeight: 'bold', marginBottom: 0, color: '#333', textAlign:'left' }}>
                                                Current Statistics
                                        </h3>  
                                            <Col xs="12" sm="12" md="4" lg="4" xl="4" style={{marginTop:5}}>
                                                <Card className='px-2 py-3' style={{borderRadius: 15, background: 'linear-gradient(to right, #2c3e50, #34495e)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', textAlign: 'center', height:150  }}>
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <FaHourglassEnd style={{ color: 'white', width: 35, height: 35 }} />
                                                        <h6 style={{ marginTop: 10, color: 'white', fontSize: '14px', fontWeight: 'bold', letterSpacing: '1px' }}>
                                                            TICKETS REMAINING
                                                        </h6>
                                                        <h5 style={{ color: 'white', fontSize: '18px'}}>
                                                            {currentTicket.ESTIMATED_TICKETS_REMAINING && parseInt(currentTicket.ESTIMATED_TICKETS_REMAINING).toFixed(0)} ({currentTicket.ESTIMATED_PCT_REMAINING && (currentTicket.ESTIMATED_PCT_REMAINING*100).toFixed(0)}%)
                                                        </h5>
                                                    </div>
                                                </Card>
                                            </Col>
                                            <Col xs="12" sm="12" md="4" lg="4" xl="4" style={{marginTop:5}}>
                                                <Card className='px-2 py-3' style={{borderRadius: 15, background: 'linear-gradient(to right, #2c3e50, #34495e)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', textAlign: 'center', height:150  }}>
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <FaCashRegister style={{ color: 'white', width: 35, height: 35 }} />
                                                        <h6 style={{ marginTop: 10, color: 'white', fontSize: '14px', fontWeight: 'bold', letterSpacing: '1px' }}>
                                                            NET EXPECTED VALUE
                                                        </h6>
                                                        <h5 style={{ color: 'white', fontSize: '18px'}}>
                                                            ${currentTicket.CURRENT_NET_EXPECTED_VALUE && currentTicket.CURRENT_NET_EXPECTED_VALUE.toFixed(2)}
                                                        </h5>
                                                    </div>
                                                </Card>
                                            </Col>

                                            <Col xs="12" sm="12" md="4" lg="4" xl="4" style={{marginTop:5}}>
                                                <Card className='px-2 py-3' style={{borderRadius: 15, background: 'linear-gradient(to right, #2c3e50, #34495e)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', textAlign: 'center', height:150  }}>
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <ShowChartIcon style={{ color: 'white', width: 35, height: 35 }} />
                                                        <h6 style={{ marginTop: 10, color: 'white', fontSize: '14px', fontWeight: 'bold', letterSpacing: '1px' }}>
                                                            ROI
                                                        </h6>
                                                        <h5 style={{ color: 'white', fontSize: '18px'}}>
                                                            {currentTicket.CURRENT_ROI && currentTicket.CURRENT_ROI.toFixed(2)}%
                                                        </h5>
                                                    </div>
                                                </Card>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <h3 className="px-3 py-2" style={{ fontWeight: 'bold', marginBottom: 0, color: '#333', textAlign:'left' }}>
                                                Original Statistics
                                            </h3>  
                                            <Col xs="12" sm="12" md="4" lg="4" xl="4" style={{marginTop:5}}>
                                                <Card className='px-2 py-3' style={{borderRadius: 15, background: 'linear-gradient(to right, #2c3e50, #34495e)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', textAlign: 'center', height:150 }}>
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <FaCalendarAlt style={{ color: 'white', width: 35, height: 35 }} />
                                                        <h6 style={{ marginTop: 10, color: 'white', fontSize: '14px', fontWeight: 'bold', letterSpacing: '1px' }}>
                                                            START DATE
                                                        </h6>
                                                        <h5 style={{ color: 'white', fontSize: '18px'}}>
                                                            {(currentTicket.START_DATE && moment(currentTicket.START_DATE).format('MMMM D, YYYY')) || "Not Found"}
                                                        </h5>
                                                    </div>
                                                </Card>
                                            </Col>
                                            <Col xs="12" sm="12" md="4" lg="4" xl="4" style={{marginTop:5}}>
                                                <Card className='px-2 py-3' style={{borderRadius: 15, background: 'linear-gradient(to right, #2c3e50, #34495e)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', textAlign: 'center', height:150 }}>
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <AttachMoneyIcon style={{ color: 'white', width: 35, height: 35 }} />
                                                        <h6 style={{ marginTop: 10, color: 'white', fontSize: '14px', fontWeight: 'bold', letterSpacing: '1px' }}>
                                                            PRICE
                                                        </h6>
                                                        <h5 style={{ color: 'white', fontSize: '18px'}}>
                                                            ${currentTicket.PRICE}
                                                        </h5>
                                                    </div>
                                                </Card>
                                            </Col>
                                            <Col xs="12" sm="12" md="4" lg="4" xl="4" style={{marginTop:5}}>
                                                <Card className='px-2 py-3' style={{borderRadius: 15, background: 'linear-gradient(to right, #2c3e50, #34495e)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', textAlign: 'center', height:150  }}>
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <TbSum style={{ color: 'white', width: 35, height: 35 }} />
                                                        <h6 style={{ marginTop: 10, color: 'white', fontSize: '14px', fontWeight: 'bold', letterSpacing: '1px' }}>
                                                            TICKETS PRINTED
                                                        </h6>
                                                        <h5 style={{ color: 'white', fontSize: '18px'}}>
                                                        {currentTicket.TICKETS_PRINTED && Math.round(typeof currentTicket.TICKETS_PRINTED === 'string' ? Number(currentTicket.TICKETS_PRINTED) : currentTicket.TICKETS_PRINTED)}
                                                        </h5>
                                                    </div>
                                                </Card>
                                            </Col>
                                            <Col xs="12" sm="12" md="4" lg="4" xl="4" style={{marginTop:15}}>
                                                <Card className='px-2 py-3' style={{borderRadius: 15, background: 'linear-gradient(to right, #2c3e50, #34495e)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', textAlign: 'center', height:150  }}>
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <HiTrophy style={{ color: 'white', width: 35, height: 35 }} />
                                                        <h6 style={{ marginTop: 10, color: 'white', fontSize: '14px', fontWeight: 'bold', letterSpacing: '1px' }}>
                                                            ODDS OF WINNING
                                                        </h6>
                                                        <h5 style={{ color: 'white', fontSize: '18px'}}>
                                                            {currentTicket.ODDS_OF_WINNING ? (currentTicket.ODDS_OF_WINNING.includes("1 in") ? currentTicket.ODDS_OF_WINNING : "1 in " + currentTicket.ODDS_OF_WINNING) : "Not Found"}
                                                        </h5>
                                                    </div>
                                                </Card>
                                            </Col>
                                            <Col xs="12" sm="12" md="4" lg="4" xl="4" style={{marginTop:15}}>
                                                <Card className='px-2 py-3' style={{borderRadius: 15, background: 'linear-gradient(to right, #2c3e50, #34495e)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', textAlign: 'center', height:150  }}>
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <FaCashRegister style={{ color: 'white', width: 35, height: 35 }} />
                                                        <h6 style={{ marginTop: 10, color: 'white', fontSize: '14px', fontWeight: 'bold', letterSpacing: '1px' }}>
                                                            NET EXPECTED VALUE
                                                        </h6>
                                                        <h5 style={{ color: 'white', fontSize: '18px'}}>
                                                            ${currentTicket.ORIGINAL_NET_EXPECTED_VALUE && currentTicket.ORIGINAL_NET_EXPECTED_VALUE.toFixed(2)}
                                                        </h5>
                                                    </div>
                                                </Card>
                                            </Col>

                                            <Col xs="12" sm="12" md="4" lg="4" xl="4" style={{marginTop:15}}>
                                                <Card className='px-2 py-3' style={{borderRadius: 15, background: 'linear-gradient(to right, #2c3e50, #34495e)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', textAlign: 'center', height:150  }}>
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <ShowChartIcon style={{ color: 'white', width: 35, height: 35 }} />
                                                        <h6 style={{ marginTop: 10, color: 'white', fontSize: '14px', fontWeight: 'bold', letterSpacing: '1px' }}>
                                                            ROI
                                                        </h6>
                                                        <h5 style={{ color: 'white', fontSize: '18px'}}>
                                                            {currentTicket.ORIGINAL_ROI && currentTicket.ORIGINAL_ROI.toFixed(2)}%
                                                        </h5>
                                                    </div>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                {currentTicket.HOW_TO_PLAY && <Row style={{borderBottom:'solid 1px #C2C2C2'}}>
                                    <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                        <div className="px-2 py-2">
                                            <h3 style={{ fontWeight: 'bold', marginBottom: 15, color: '#333', textAlign:'left' }}>
                                                How To Play
                                            </h3>  
                                           <p style={{textAlign:'left'}}>{currentTicket.HOW_TO_PLAY}</p>
                                        </div>
                             
                                    </Col>
                           
                                </Row>}
                                <Row style={{borderBottom:'solid 1px #C2C2C2', paddingBottom:15}}>
                                    <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                        <div className="px-2 py-2">
                                            <h3 style={{ fontWeight: 'bold', marginBottom: 0, color: '#333', textAlign:'left' }}>
                                                Prize Table
                                            </h3>  
                                           
                                        </div>
                                        <PrizeTable data={currentTicket["PRIZE_TABLE"]} ></PrizeTable>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                    <div className="px-2 py-2">
                                        <h3 style={{ fontWeight: 'bold', marginBottom: 25, color: '#333', textAlign: 'left' }}>
                                            Comments
                                        </h3>
                                        {comments.length === 0 && 
                                            <p style={{ textAlign: 'center', color: '#888' }}>No comments available.</p>
                                        }
                                        {comments.length > 0 && (
                                            <div>
                                                {comments.map((comment) => (
                                                    <div key={comment.comment_id} style={{ textAlign: 'left', marginBottom: '10px'}}>
                                                        <CommentBox  comment={comment} />
                                                    </div>
                                                ))}
                                            </div>
                                        )}

                                    </div>

                                  
                                    </Col>
                                </Row>
                            </Card>
                        </Row>
                    </Container>
                )}
            </div>}
            {isCurrentPageLoading && (
                <div className="overlay">
                    <CircularProgress style={{width:100, height:100}}/>
                </div>
            )}
            <CommentDialog isCommentDialogOpen={isCommentDialogOpen} setIsCommentDialogOpen={setIsCommentDialogOpen} rating = {rating} handleRatingChange ={handleRatingChange} handleAlertMessage={handleAlertMessage} auth={auth} isAuth={isAuth} isAuthVerified={isAuthVerified} currentTicket={currentTicket} setIsCurrentPageLoading={setIsCurrentPageLoading} initializePage={initializePage}/>
        </div>

    );
}

export default TicketFullPage;
