import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import { Box } from '@mui/material';

const PrizeTable = ({ data }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const customColumnOrder = [
        "PRIZE_AMOUNT",
        "ORIGINAL_PRIZE_COUNT",
        "CURRENT_PRIZE_COUNT",
        "ORIGINAL_PRIZE_AMOUNT_SUM",
        "CURRENT_PRIZE_AMOUNT_SUM",
        "ORIGINAL_PROBABILITY",
        "CURRENT_PROBABILITY",
    ];

    const customColumnNames = {
        PRIZE_AMOUNT: "Prize Amount",
        ORIGINAL_PRIZE_COUNT: "Total Prizes",
        CURRENT_PRIZE_COUNT: "Prizes Remaining",
        ORIGINAL_PRIZE_AMOUNT_SUM: "Original Prize Amount Sum",
        CURRENT_PRIZE_AMOUNT_SUM: "Current Prize Amount Sum",
        ORIGINAL_PROBABILITY: "Original Probability",
        CURRENT_PROBABILITY: "Current Probability",
    };

    const columns = customColumnOrder.filter(header => data[header] !== undefined);

    // Generate rows based on available columns
    const rows = columns.length > 0
        ? data[columns[0]].map((_, index) => {
              const row = { id: index }; 
              columns.forEach(header => {
                  row[header] = data[header][index];
              });
              return row;
          })
        : [];

    return (
        <div>
            <Box sx={{ width: '100%', overflowX: 'auto', display:'flex', justifyContent:'center' }}>
                <TableContainer component={Paper} sx={{ maxWidth: isMobile ? '90vw' : '100%', borderRadius: 2 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {customColumnOrder.map(header => (
                                    <TableCell
                                        key={header}
                                        sx={{
                                            fontSize: isMobile ? '0.75rem' : '1rem',
                                            fontWeight: 'bold',
                                            backgroundColor: theme.palette.grey[200],
                                            minWidth: isMobile ? 120 : 150,
                                            padding: '16px'
                                        }}
                                    >
                                        <Typography variant="subtitle2" noWrap>{customColumnNames[header]}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map(row => (
                                <TableRow key={row.id} sx={{ '&:nth-of-type(even)': { backgroundColor: theme.palette.grey[100] } }}>
                                    {customColumnOrder.map(header => (
                                        <TableCell
                                            key={header}
                                            sx={{
                                                fontSize: isMobile ? '0.75rem' : '1rem',
                                                padding: '16px',
                                                minWidth: isMobile ? 120 : 150
                                            }}
                                        >
                                    {typeof row[header] === 'number'
                                        ? Number.isInteger(row[header])
                                            ? row[header].toLocaleString()
                                            : row[header] >= 1
                                                ? row[header].toFixed(2)
                                                : row[header] >= 0.01
                                                    ? row[header].toFixed(3)
                                                    : row[header]
                                        : row[header]}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </div>
    );
};

export default PrizeTable;
