// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { GoogleAuthProvider } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "scratchie-89cc7.firebaseapp.com",
  projectId: "scratchie-89cc7",
  storageBucket: "scratchie-89cc7.appspot.com",
  messagingSenderId: "268070720613",
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: "G-XD1ZTFYE4N"
};

let app, analytics, provider;
try {
    app = initializeApp(firebaseConfig);
    analytics = getAnalytics(app);
    provider = new GoogleAuthProvider();
    console.log("Firebase initialized successfully");
  } catch (error) {
    console.error("Firebase initialization error:", error);
  }

export { app, analytics, provider};
