import React, { useRef, useEffect, useState } from 'react';

function TicketImage({ src, alt }) {
    const imageRef = useRef(null);
    const [imageStyle, setImageStyle] = useState({
        width: '100%',
        display: 'block'
    });

    useEffect(() => {
        const img = imageRef.current;
        if (img) {
            const handleLoad = () => {
                const renderedHeight = img.clientHeight;
                console.log('Image loaded. Rendered height:', renderedHeight);
                if (renderedHeight > 599) {
                    console.log('Setting height to 565px');
                    setImageStyle(prevStyle => ({
                        ...prevStyle,
                        height: '600px',
                        objectFit: 'cover'
                    }));
                }
            };

            if (img.complete) {
                // If the image is already loaded (e.g., from cache), the load event won't fire again
                handleLoad();
            } else {
                // Add load event listener
                img.addEventListener('load', handleLoad);

                // Clean up the event listener
                return () => {
                    img.removeEventListener('load', handleLoad);
                };
            }

            // Additional check to handle cases where image might already be rendered
            // but load event has not triggered yet
            handleLoad();
        }
    }, []);

    return (
        <img 
            ref={imageRef}
            src={src} 
            alt={alt} 
            style={imageStyle}
        />
    );
}

export default TicketImage;
