// Import action types
import { SET_FEED, CLEAR_FEED } from '../actions/types';

// Initial state for the feed slice
const initialState = [];

// Feed reducer function
const feedReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FEED:
      return action.payload; // Directly return the payload as the new state
    case CLEAR_FEED:
      return []; // Clear the feed by returning an empty array
    default:
      return state;
  }
};

export default feedReducer;